li{
    list-style: none;
    display: inline-block;
    padding: 0 20px;
}

a:hover {
    color: #fffbfb9d;
}

a{
    text-decoration: none;
    color: #ffffff;
}

span{
    color: white;
}
.hamburger{
    display: none;
}


.navbarbodder{
    border-right: 1px solid #ffffff6e;
}
.navbare{
    background-color: #0d0d0eb8;
    position: fixed;
    padding: 10px 0px; 
    z-index: 100;
}
@media (max-width: 998px) {
    .bodyafter::after{
        content: "";
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.404);
        position: fixed;
        top: 0;
        z-index: 10;
    }
    .navbarhide ul{
        display: none !important;
    }
    .navbartop{
        display: none !important;
    }

    .navbartopmobile{
        display: block !important;
        position: absolute ;
        top: 55px;
        background-color: #0d0d0eb8;
    }
    .hamburger{
        display: block !important; 
       
    } 
    
    .navbarhide{
        padding: 0px 5px !important;
    }
    .navbarlogo{
        padding: 0px 5px !important;
    }
    .navbare::after {
       display: none;
    }
    .navbarinner{
        position: fixed;
        width: 100%;
        background-color: #0d0d0eb8;
        padding: 10px;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;

    }
    .hamburger span{
        font-size: 25px;
    }
    .navbare{
        background-color: transparent;
    }
}

