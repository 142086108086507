.client{
    padding: 100px 0px;
}

.topclient{
    max-width: 500px;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
}

.topclient h6{
    padding: 8px 26px;
    border: 2px solid #155bd5;
    display: inline-block;
    border-radius: 29px;
    color: #155bd5;
    font-weight: 600;
}
 
.singleimage{
    display: inline-block;
    margin: 13px 10px;
    background-color: var(--white);
    line-height: 100px;
    padding: 8px 25px;
    border-radius: 8px;
    border: 1px solid #eee;
    transition: all .4s;
}
.singleimage img{
    max-width: 169px;
}

.bottomclient{
    max-width: 710px;
    margin: auto;
    text-align: center;
}

.singleimage:hover{
    box-shadow: 0 10px 30px rgba(0,0,0,.096);
    transform: translateY(-5px);
}