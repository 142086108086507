.business{
    padding: 100px 0px;
    background: linear-gradient(45deg,#155bd5,#1c3ab6);
    position: relative;

}
.business::after{
    content: "";
    background-image: url("/public/images/overlay.png");
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.topbusiness h2{
    color: #ffffff;
    font-weight: 700;
}
.topbusiness p{
    color: #ffffff;
}
.innerbusiness{
    max-width: 550px;
    margin: auto;
}

