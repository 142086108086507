
.mobilemenuleftslide{
    display: none;
}



@media (max-width: 998px){
    .activemobile{
        background: #155bd5;
        padding: 6px 20px 8px 20px;
        border-radius: 4px;
        color: #ffffff !important;
    }
    .mobilemenuleftslide{
        display: block;
        position: fixed; 
        top: 0;
        left: -100%;
        color: black;
        transition: all 0.5s;
        padding: 20px;
        background-color: #ffffff;
        height: 100%;
    }
   
    .mobilemenuleft{
        padding: 20px;
        position: fixed;
        top: 0;
        left: 0px;
        transition: all 0.5s;
        background-color: #ffffff;
        height: 100%; 

    }

    li{
        display: block;
        padding: 10px 0px;
    }
     
    .navbareinner{
        background: black;
        position: fixed;
        width: 100%;
    }
    .mobilesocial{
        font-size: 11px;
        color: #000000;
    }
    .mobileicon{
        color: #000000;
    }
    .mobilemenuleft:after {
        content: "";
        width: 100%;
        height: 1px;
        background: #0000006b;
        left: 0;
        top: 105px;
        position: absolute;
    }
    .mobinavi a{
        color: #000000;
    }
  
}
