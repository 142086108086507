.contact{
    padding-top: 100px;
    position: relative;
    z-index: 10;
}


.contactboxx{
    display: flex;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-bottom: 29px;
    transition: all 0.4s;
}
.contactboxx:hover{
    box-shadow: 0px 8px 19px #60617029;
}

.cicons{
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #155bd5;
}

.cicons svg{
    color: #ffffff;
    font-size: 25px;
}


.contactdiscription h4{
    color: #155bd5;
    font-size: 20px;
    font-weight: 600;
}

.contactdiscription p{
    margin: 0;
}





 
.innerrightcontact{
    padding: 50px 40px;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    border-radius: 10px;
    margin-left: 29px;
    transition: all 0.4s;
    min-height: 631px;
}
.innerrightcontact:hover{
    box-shadow: 0px 8px 19px #60617029;
}
.topcontact{
    max-width: 500px;
    margin: auto;
    text-align: center;
}

.topcontact span{
    color: #155bd5;
    font-size: 20px;
    font-weight: 700;
}

.bottomcontact input{
  padding: 15px 25px;
  width: 100%;
  border-radius: 29px;
  border: 1px solid #e0e0e0;
  outline: none;
}

.bottomcontact textarea{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    padding: 15px 25px;
    resize: none;
    outline: none;
}

.contactbutton{
    background-color: #155bd5;
    color: #ffffff;
    border: none;
    padding: 10px 24px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
}

.errorpara{
    color: red;
    font-size: 11px;
    position: absolute;
    margin-left: 25px;
    font-family: intersemi;
}

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;

}

.thanksheading{
   color: #1acd1a;
   
}
.thankspara{
    color: #155bd5;
}

.activecontact{
    background: #155bd5;
    padding: 4px 20px 8px 20px;
    border-radius: 4px;
    color: #ffffff;
}

.contacth2{
    font-size: 27px;
    margin-top: 10px;
}

@media (max-width: 998px) {
    .innerrightcontact{
        margin: 0;
    }
    
.bottomcontact input{
    padding: 10px 25px;
    width: 100%;
    border-radius: 29px;
    border: 1px solid #e0e0e0;
  
  }
  .contactboxx{
    display: flex;
    padding: 20px;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-bottom: 29px;
}
.contactdiscription{
    margin-left: 0 !important;
    margin-top: 20px;
}
}