@font-face {
  font-family: interblack;
  src: url("/public/fonts/Inter-Light.ttf");
}
@font-face {
  font-family: interbold;
  src: url("/public/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: intersemi;
  src: url("/public/fonts/Inter-SemiBold.ttf");
}

button{
  font-family: interbold !important;
}
h4{
  font-family: intersemi;
}
h5,h6{
  font-family: intersemi;
}
h1,h2,span{
  font-family: interbold;
}
img{
  max-width: 100%;
 
}
p{
  font-family: interblack;
}
.progres {
  position: fixed;
  top: 0;
  left: 0;
  background: red;
  width: 100%;
  height: 2.5px;
  transform-origin: 0 0;
  z-index: 110;
}

.fixedbutton a{
   color: black;
   
}

.fixedbutton svg{
  font-size: 29px;
  color: #ffffff;
}

.fixedbutton{
  position: fixed;
  right: 29px;
  bottom: 29px;
  padding: 8px;
  background-color: #155bd5;
  border-radius: 5px;
  z-index: 10;
  transition: all 0.5s;
}



.fixedbuttonhide svg{
  font-size: 29px;
  color: #ffffff;
}

.fixedbuttonhide{
  z-index: 10;
  position: fixed;
  right: -100px;
  bottom: 29px;
  padding: 8px;
  background-color: #155bd5;
  border-radius: 5px;
  z-index: 10;
  transition: all 0.5s;
}























