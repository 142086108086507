.footerlogo{
    color: black;
}

.footer{
    padding: 100px 0px;
}

.innerfooter li{
 display: block;
}



.footerinput{
    width: 100%;
    padding-left: 18px;
    padding-right: 65px;
    border: 1px solid #e0e0e0;
    height: 55px;
    border-radius: 10px;
}


.messageicon{
    right: 8px;
    top: 8px;
    position: absolute;
}
.messageicon button{
   border: none;
   height: 40px;
   width: 40px;
   border-radius: 5px;
   background-color: #155bd5;
}

.footermicn{
    font-size: 20x;
    color: #ffffff;
}

.innermessage{
    padding-left: 80px;
}

.footer p,li{
    color: #6d6d6d;
}

.footer span{
   color: #6d6d6d;
}

@media (max-width: 998px) {
    .innermessage{
        padding: 0px 20px;
    }
    .innerfooter{
        text-align: center;
    }
}