.service{
    padding: 100px 0px;
}

.servicepara{
    color: black;
}

.topservice{
    text-align: center;
    max-width: 500px;
    margin: auto;
    margin-bottom: 50px;
}
.topservice h6{  
    padding: 8px 26px;
    border: 2px solid #155bd5;
    display: inline-block;
    border-radius: 29px;
    color: #155bd5;
    font-weight: 600;
}
.cardimage svg{
   color: blue;
   font-size: 29px;
}
.serviceitag{
    padding: 25px;
    border-radius: 10px;
    border-radius: 50%;
    border: 2px solid blue;
}
.cardsservice{
    padding: 60px 30px;
    border-bottom: 2px solid blue;
    border-radius: 5px;
    height: 100%;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: all 0.4s;
}
.cardmargin{
    margin-bottom: 31px;
}
.cardsservice:hover .cardimage svg{
    color: white;
    transition: all 0.5s;
}
.cardsservice:hover{
    box-shadow: 0 10px 30px rgba(0,0,0,.096);
    transform: translateY(-5px);
}
.cardsservice:hover .serviceitag{
     background-color: blue;
    transition: all 0.5s;

}