

.heroh{
    font-weight: 700;
    font-size: 40px;
    color: #ffffff; 
    text-shadow: 0 3px 8px #00000017;

}

.hero{
    padding: 160px 0px;
    background-image: url("/public/images/Background-slider-homepage_01.jpg");
    background-size: cover;
}
.herop{
    margin-top: 29px;
    font-weight: 400;
    color: #ffffff;

}
.herobutton{
    background-color: white;
    color:#155bd5;
    border: none;
    padding: 10px 31px;
    font-weight: 600;
    font-size: 20px;
    margin-top: 25px;
    border-radius: 6px;
    border: 1px solid white;
}
.herobutton:hover{

    background: none;
    color: white;
}
  
.heroimage img{
   border-radius: 10px;
}


@media (max-width: 998px){
    .heroimage{
        margin-top: 25px;
        overflow: hidden;
    }
}

@media (max-width: 767px) {
    .hero{
        padding: 100px 0px;
    }
    .heroh{
        font-size: 31px;
    }
    .herobutton{
        font-size: 18px;
        padding: 8px 16px;
    }
    .heroimage img{
        width: 100%;
    }
}

.clae{
    transform: scale(1.2);
}

.fadeInUp{
    transition: all 2s;
    transform: scale(1);
}