.portpara{
   color: black;
}

.topport{
    max-width: 500px;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
}
.topport h6{
    padding: 8px 26px;
    border: 2px solid #155bd5;
    display: inline-block;
    border-radius: 29px;
    color: #155bd5;
    font-weight: 600;
}
.portfolio{
    background-color: #fafafa;
    padding: 80px 0px;
}

.portbutton{
    border: none;
    padding: 0px 29px;
    background: none;
    font-weight: 600;
    margin: 0px 4px;
    transition: all .2s;
    position: relative;
    border-bottom: 4px solid transparent;
    margin-bottom: 10px;
}

.portbutton:hover{ 
    border-bottom: 4px solid #155bd5;
    color: #155bd5;
}


.portactive{
    border-bottom: 4px solid #155bd5;
    color: #155bd5;
}


.portcontent p{
    color: black;
}

.portimage img{
    border-radius: 10px;
}

@media (max-width: 998px){
    .portimage img{
        width: 100%;
    }
}