
.about{
    padding: 60px 0px;
    background-color: #fafafa;
}

.story{
    position: relative;
    font-size: 25px;
    padding-left: 40px;
    margin-top: 10px;
}

.story::after{
    content: "";
    width: 20px;
    height: 2px;
    background-color: blue;
    display: block;
    position: absolute;
    top: 55%;
    left: 0;
}

.experiance{
    font-size: 40px;
    font-weight: 600;
    margin-top: 20px;
}

.aboutpara{
    color: black;
}

.buttonmain{
    padding: 10px 20px;
   border-radius: 10px;
    background: #efefef;
}

.aboutbutton{
   background-color: #ffffff;
   padding: 10px 18px;
   border: none;
   border-radius: 4px;
   font-weight: 600;
   margin-right: 20px;
   transition: all 0.20s;
}

.changedbg{
    background-color: #155bd5;
    color: #ffffff;
}

.show{
    display: block;
    color: black;
}

.hide{
    display: none;
}

@media (max-width: 998px){
    .aboutbutton{
       display: block;
       width: 100%;
       margin: 10px 0px !important;
     }
}

@media (max-width: 767px) {
    .experiance{
        font-size: 31px;
    }
    .aboutfive{
        margin-top: 35px;
    }
} 