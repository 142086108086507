.topreview{
    max-width: 500px;
    margin: auto;
    text-align: center;
}
.topreview h6{
    padding: 8px 26px;
    border: 2px solid #155bd5;
    display: inline-block;
    border-radius: 29px;
    color: #155bd5;
    font-weight: 600;
}

.review{
    padding: 100px 0px;
    background-color: #fafafa;
}
.reviewiteminner{
    background-color: #ffffff;
    padding: 70px 50px;
    margin: 0px 10px;
    border-radius: 10px;
    position: relative;
}


.ritembottom img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.ritembottom h5{
    font-weight: 400;
    font-size: 14px;
}

.reviewitem{
  height: 100%;
}
.reviewcircle{
    position: absolute;
    top: -34px;
    left: 50px;
    font-size: 69px;
    color: #f17924;

}

.reviewquote{
    position: absolute;
    font-size: 60px;
    top: 0;
    right: 0;
    color: #f1f0ee5e;
}

.reviewcirclebottom{
    position: absolute;
    font-size: 60px;
    bottom: -20px;
    color: #ffac42;
    right: -6px;
}